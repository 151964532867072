import React, { useEffect, useRef, useState } from "react";
import { GROUPS, OPEN_CHATS } from "../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from "react-custom-scrollbars";
import "./SingleChat.css";
import ExportChat from "./ExportChat";
import { $crud } from "../../helper/CrudFactory";
import { BASE_URL, KJUUP_COLORS, TOGGLE_DRAWER } from "../../helper/constants";
import { AiFillMinusCircle } from "react-icons/all";
import LanguageContext from "../../helper/LanguageContext";
import _ from "lodash";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import AddProjectUser from "./AddProjectUser";
import AddProjectChatUser from "./AddProjectChatUser";
import './GroupInfo.css';
import { AiOutlineCheck, AiOutlineClose } from 'react-icons/ai';
import ActiveInactiveLable from "../../components/ActiveInactiveLable";
import RemoveUserFromInfo from "../../components/RemoveUserFromInfo";
import InviteExternalComponentPOL from "../../components/InviteExternalComponent";
import {
  EmailIcon,
  EmailShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";


const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    overflowX: "hidden",
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function UserInfo(props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector((state) => state.user);
  const [loaded, setLoaded] = useState(false);
  const [chatMessages, setChatMessages] = useState("");
  const [exportChats, setExportChats] = useState(false);
  const [admin, setAdmin] = useState({});
  const [addMember, setAddMember] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [users, setUsers] = useState([]);
  const { isGerm } = React.useContext(LanguageContext);
  const [allUsers, setAllUsers] = useState(props.users ? props.users : []);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(null);
  const [editedDescription, setEditedDescription] = useState('');
  const [editedName, setEditedName] = useState('');
  const [showFullDescription, setShowFullDescription] = useState(false);
  const textareaRef = useRef(null);
  const [groupName, setGroupName] = useState(props.chat?.name);
  const [isEditingName, setIsEditingName] = useState(false);
  const [deeplink, setDeepLink] = useState("");
  const userN = useSelector((state) => state.user);



  const handleEditClick = () => {
    setIsEditing(true);
    setEditedDescription(description);
  };

  const handleEditClickName = () => {
    setIsEditingName(true);
    setEditedName(groupName);
  };

  useEffect(() => {
    setIsEmailOn(userN?.emailNotification && userN?.emailVerified)
  }, [userN])

  const handleSave = async () => {
    setDescription(editedDescription);
    setIsEditing(false);
    const { data } = await callAPI(`/group/changeGroupDescription`, {}, "POST", {
      id: props.chat.id,
      description: editedDescription
    });
    if (data.status) {
      // await $crud.notify({
      //   type: "success",
      //   message: data.message,
      // });
    }
    else {
      await $crud.notify({
        type: "error",
        message: data.message,
      });
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsEditingName(false)
    setEditedDescription('');
  };

  const handleInputChange = (e) => {
    setEditedDescription(e.target.value);
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [description]);

  const [addUserAction, setAddUserAction] = useState({
    status: false,
    id: props.project.id,
    isInternal: props.project.isInternal,
    cID: props.project.cID
  });
  const [addChatUserAction, setAddChatUserAction] = useState({
    status: false,
    id: props.project.id,
    isInternal: props.project.isInternal,
    cID: props.project.cID
  });
  const getDetails = async () => {
    const id = props.chat.id;
    const { data } = await callAPI(`/group/${id}/info`, {}, "GET");
    if (data?.status) {
      const isExist = await data?.groupInfo?.users?.find((u) => u.id === user?.id);
      setDescription(data.groupInfo.description)
      if (isExist) {
        setIsMember(isExist.isLeave)
        setIsAdmin(isExist.isAdmin);
        setUsers(data?.groupInfo?.users);
      }
      const adminDetails = await data?.groupInfo?.users?.find(
        (user) => user.isAdmin
      );
      setAdmin(adminDetails);
      setLoaded(true);
    }
  };

  const exportChat = async () => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const id = props.chat.id;
    const { data } = await callAPI(
      `/group/${id}/export`,
      {
        timezone: timeZone,
        lang: isGerm ? "de" : "en",
        viewByOwner: false
      },
      "GET"
    );
    if (data.status) {
      setChatMessages(data.messages);
      setExportChats(true);
    }
  };

  const toggleBlockUser = async () => {
    await $crud.confirm({
      title: `${isGerm ? DE.are_you_sure_you_want_leave_chat : EN.are_you_sure_you_want_leave_chat}`,
      textContent: "",
      options: {
        ok: "Ok",
        cancel: `${isGerm ? DE.cancel : EN.cancel}`,
      },
    });
    const id = props.chat.id;
    // await props.leaveProject(id)
    const userId = user?.id;
    const { data } = await callAPI(`/group/${id}/leave`, {}, "DELETE", {
      id: id,
      user: userId,
    });
    props.hideGroup()
    getDetails();
    props.handleClose();
  };
  const viewSearch = () => {
    props.setSearch();
    props.handleClose();
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleAddUser = () => {
    // setAddMember(true);
    setAddUserAction({
      status: true,
      id: props.project.id,
      addAsMember: true,
      isInternal: props.project.isInternal,
      cID: props.project.cID
    });
  };

  const removeMember = async (actionUser) => {
    await $crud.confirm({
      title: "",
      textContent: isGerm ? "Wollen Sie den/die Teilnehmer/in wirklich aus der Chat Gruppe entfernen?" : "You want to remove this user from group?",
      options: {
        ok: isGerm ? DE.ok : "Ok",
        cancel: isGerm ? DE.cancel : "Cancel",
      },
    });
    const { data } = await callAPI(
      `/group/${props.chat.id}/remove`,
      {
        user: actionUser?.id,
      },
      "DELETE"
    );
    if (data.status) {
      getDetails();
    }
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };


  const displayDescription = () => {
    if (!description) return !isGerm ? "Add description..." : "Beschreibung hinzufügen...";
    if (showFullDescription) return description;
    if (description.length > 50) return `${description.slice(0, 50)}`;
    return description;
  };


  const handleSaveGroupname = async () => {
    // Here, you'd typically have logic to save the edited group name
    // For this example, let's set it back to the initial prop value
    if (editedName) {
      setGroupName(editedName);
      setIsEditingName(false);
      const { data } = await callAPI(`/group/changeGroupName`, {}, "POST", {
        id: props.chat.id,
        name: editedName
      });
      if (data.status) {
        // await $crud.notify({
        //   type: "success",
        //   message: data.message,
        // });
      }
      else {
        await $crud.notify({
          type: "error",
          message: data.message,
        });
      }
    }
    else {
      await $crud.notify({
        type: "error",
        message: !isGerm ? "Add group name" : "Gruppennamen hinzufügen",
      });
    }
  };

  const handleInputChangeNAme = (e) => {
    setEditedName(e.target.value);
  };

  const navigateUserProfile = (userId) => {
    dispatch({ type: TOGGLE_DRAWER, open: true })
    history.push(`/user-profile/${userId}`)
  }

  const getInviteLink = async () => {
    const id = props.chat.id;
    const { data } = await callAPI(
      `/invite-user/deeplink`,
      {
        inviteType: 'group',
        group: id,
        buildingList: props.project.id,
      },
      "POST"
    );
    if (data.status) {
      let splitToken = data?.deeplinkUrl?.split("/");
      let getToken = splitToken[splitToken?.length - 1];
      let webLinkPayload = {
        data: {
          locallyIndex: true,
          title: "Kjuup",
          ratingAverage: 4.2,
          registraTionToken: getToken,
        },
      };
      window.branch.link(webLinkPayload, (err, link) => {
        setDeepLink(link);
      });
    }
  };

  const getString = () => {
    if (isGerm) {
      return `Teilen Sie diesen Link, um ein Nicht-Mitglied von Kjuup zum Raum ${props.project.name} und zur Chat Gruppe ${props.chat?.name} einzuladen. Der komplette Chatverlauf wird geteilt. Diesen Link können mehrere Personen nutzen.\n\n${deeplink}`
    }
    else {
      return `Share this link to invite a non-member of Kjuup to the room ${props.project.name} and the chat group ${props.chat?.name}. The entire chat history will be shared. This link can be used by multiple people.\n\n${deeplink}`
    }
  }

  const copyLink = async () => {
    const Url = getString()
    navigator.clipboard.writeText(Url).then(r => {
      $crud.notify({
        type: 'success',
        message: isGerm ? DE.link_copied : EN.link_copied
      })
    })
  }

  const [isEmailOn, setIsEmailOn] = useState(props.isEmailAllowed && userN?.emailVerified)

  const _onPreEmailNotification = async () => {
    let emailNotification = !isEmailOn
    setIsEmailOn(emailNotification)
    await callAPI(`/group/emailNotification`, {}, "PUT", {
      emailNotification,
      group: props.chat.id
    });
  }

  return (
    <React.Fragment>
      {!addMember && !addChatUserAction.status && !addUserAction.status && (
        <div className={props.isInternal ? "w-300  pt-0 h45vh immoanimation-enter-done p-0 chat_user_info group_chat_user_info border-radius-0 m-0 chat_user_info-internal" : "w-300  pt-0 h45vh immoanimation-enter-done p-0 chat_user_info group_chat_user_info border-radius-0 m-0"}
          style={{
            display: "flex", flex: 1, flexDirection: "column", height: "93vh",
            borderColor: props.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color,
            backgroundColor: "white"
          }}
        >
          <div className={props.isInternal ? "main_heading main_heading-internal" : "main_heading"} style={{ display: "flex" }}>
            <a className="pointer" onClick={props.handleClose} style={{}}>
              {!props.isInternal ?
                <img
                  width={10}
                  src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                />
                :
                <img
                  width={10}
                  className="custom-svg"
                  src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                />
              }
            </a>
            <div style={{ flex: 1, textAlign: "center" }}>
              {isGerm ? DE.user_info : EN.user_info}
            </div>
            <div />
          </div>
          <div className="group-name-container" style={{ paddingTop: 10, borderBottom: "1px solid #dfdfdf", borderTop: "0.5px solid #dfdfdf" }}>
            {isEditingName ? (
              <div className="edit-mode" style={{ marginLeft: 10, marginRight: 10 }}>
                <input
                  className="edit-input"
                  value={editedName}
                  onChange={handleInputChangeNAme}
                  placeholder={!isGerm ? "Add group name..." : "Gruppennamen hinzufügen..."}
                  style={{ fontWeight: "500", fontSize: "15px" }}
                />
                <div className="edit-buttons" style={{ marginTop: 10 }}>
                  <button className="action-button" onClick={handleSaveGroupname}>
                    <AiOutlineCheck /> {/* Save icon */}
                  </button>
                  <button className="action-button" onClick={handleCancel}>
                    <AiOutlineClose /> {/* Cancel icon */}
                  </button>
                </div>
              </div>
            ) : (
              <div className="description-containerx" >
                <div className="description-dd" style={{ fontWeight: "500", fontSize: "15px" }}>
                  {props.chat?.defaultChatGroup
                    ? isGerm
                      ? "Alle"
                      : "All"
                    : groupName}
                  <div>
                    {(!props.chat?.defaultChatGroup && isAdmin && (!props.fromActivity && !props.project.isDone)) && (
                      <button className="edit-buttonx" onClick={handleEditClickName}>
                        <img
                          width={18}
                          src={
                            process.env.PUBLIC_URL +
                            (props.isInternal ? "/assets/img/edit-green.png" : "/assets/img/edit.png")
                          }
                          style={{}}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          {!props.isInternal ?
            <div onClick={(e) => { getInviteLink() }} style={{ marginBottom: 10, display: "flex", flexDirection: "column" }}>
              <InviteExternalComponentPOL
                onClick={(e) => { }}
                id={'contacts'}
              />
              {deeplink ?
                <p style={{ width: "90%", alignSelf: "center", fontSize: '14px', color: "grey", fontStyle: "italic" }}>
                  {getString().replace(deeplink, "")}
                </p>
                :
                <></>
              }
              {deeplink ?
                <div className="mt-0 p-2 pt-0" style={{ alignSelf: "center", textAlign: "center" }}>
                  <div onClick={copyLink} className="pointer">
                    <img
                      width={30}
                      src={process.env.PUBLIC_URL + "/assets/img/Copy link.svg"}
                    />
                    <br />
                    <span
                      style={{
                        fontSize: "13px",
                        color: "#f18438"
                      }}
                    >
                      {isGerm ? DE.copy_link : EN.copy_link}
                    </span>
                  </div>
                </div>
                :
                <></>
              }
              {deeplink ?
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 0, fontStyle: "italic" }}>
                  <span style={{
                    textAlign: "center", fontSize: "14px",
                    color: "grey", fontStyle: "italic"
                  }}>{isGerm ? DE.link_expire.replace('72 hours', '10 days').replace('72 Stunden gültig.', '10 Tage gültig.') : EN.link_expire.replace('72 hours', '10 days').replace('72 Stunden gültig.', '10 Tage gültig.')}</span>
                </div>
                :
                <></>
              }
            </div>
            :
            <></>
          }
          <div className="wscroll right_wscroll" style={{ flex: 1, backgroundColor: "white" }}>
            <CustomScrollbars
              autoHide
              autoHideTimeout={200}
              autoHideDuration={200}
              className="bottom-bar"
            >
              <div className="company_services m-0 chat_services">
                <div className="click_plus">
                  {(isAdmin) ?
                    <div className="group-info-container">
                      {isEditing ? (
                        <div className="edit-mode">
                          <textarea
                            className="edit-textarea"
                            rows="4"
                            cols="50"
                            value={editedDescription}
                            onChange={handleInputChange}
                            placeholder={!isGerm ? "Add description..." : "Beschreibung hinzufügen..."}
                            ref={textareaRef}
                            style={{ fontWeight: "500", fontSize: "15px" }}
                          />
                          <div className="edit-buttons">
                            <button className="action-button" onClick={handleSave}>
                              <AiOutlineCheck /> {/* Save icon */}
                            </button>
                            <button className="action-button" onClick={handleCancel}>
                              <AiOutlineClose /> {/* Cancel icon */}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="description-container">
                          <span className="description-text" style={{ color: description ? "#303030" : "grey", fontWeight: "500", fontSize: "15px" }}>
                            {displayDescription()}
                            {description && description.length > 50 && (
                              <span onClick={toggleDescription} className="show-more" style={{ color: props.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }}>
                                {showFullDescription ? ` ${isGerm ? DE.see_less : EN.see_less}` : ` ${isGerm ? DE.see_more : EN.see_more}`}
                              </span>
                            )}
                          </span>
                          {!showFullDescription && (!props.fromActivity && !props.project.isDone) && (
                            <button className="edit-button" onClick={handleEditClick}>
                              <img
                                width={18}
                                src={
                                  process.env.PUBLIC_URL +
                                  (props.isInternal ? "/assets/img/edit-green.png" : "/assets/img/edit.png")
                                }
                                style={{ marginBottom: 5 }}
                              />
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    :
                    description ?
                      <div className="group-info-container">
                        <div className="description-container">
                          <span className="description-text" style={{ color: description ? "#303030" : "grey", fontWeight: "500", fontSize: "15px" }}>
                            {displayDescription()}
                            {description && description.length > 50 && (
                              <span onClick={toggleDescription} className="show-more">
                                {showFullDescription ? ` ${isGerm ? DE.see_less : EN.see_less}` : ` ${isGerm ? DE.see_more : EN.see_more}`}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      :
                      <></>
                  }
                  <ul className="list-group">
                    <li className={props.isInternal ? "li-internal" : "pointer"} onClick={props.viewMediaDoc}>
                      <span>
                        <img
                          width="25px"
                          src={
                            process.env.PUBLIC_URL + "/assets/img/add-media.svg"
                          }
                        />
                      </span>{" "}
                      {isGerm ? DE.media_link_docs : EN.media_link_docs}
                    </li>
                    {/* <li className={props.isInternal ? "li-internal" : "pointer"} onClick={viewSearch}>
                      <span>
                        <img
                          width="20px"
                          src={
                            process.env.PUBLIC_URL + "/assets/img/search.svg"
                          }
                        />
                      </span>{" "}
                      {isGerm ? DE.search_chat : EN.search_chat}
                    </li> */}
                    {users && (
                      <div
                        style={{
                          borderTop: "15px solid #f7f7f7",
                          borderBottom: "15px solid #f7f7f7",
                        }}
                      >
                        {/* {isAdmin && !props.chat.defaultChatGroup && !props.project.viewByOwner &&
                          !props.chat.name.includes(
                            isGerm ? DE.all_member_of_project : EN.all_member_of_project
                          ) && (!props.fromActivity && !props.project.isDone) && (
                            <li className="pointer" style={{ display: "flex" }} onClick={handleAddUser}>
                              <span>
                                <img
                                  width="20px"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/add-user.svg"
                                  }
                                />
                              </span>
                              <span style={{ flex: 1 }} className={props.isInternal ? "main_heading-internal" : "main-heading"}>
                                {isGerm ? DE.add_user_project : EN.add_user_project}
                              </span>
                            </li>
                          )} */}
                        {users &&
                          loaded &&
                          _.sortBy(users, [us => us?.lastName?.toLowerCase()], ['lastName']).map((u, index) => (
                            !u.isLeave &&
                            <li key={index} className={props.isInternal ? "li-internal" : "pointer"}>
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <div>
                                  <img
                                    src={
                                      u?.avatar
                                        ? BASE_URL + u?.avatar
                                        : process.env.PUBLIC_URL +
                                        "/assets/img/head.svg"
                                    }
                                    style={{ borderRadius: 100, height: 30, width: 30 }}
                                  />
                                </div>
                                <div
                                  // className="col-5"
                                  style={{ paddingLeft: 10, flex: 1, display: "flex", alignItems: 'center' }}
                                  onClick={() => u?.id !== user?.id && navigateUserProfile(u?.id)}
                                >
                                  {u?.id === user?.id ? isGerm ? DE.me : EN.me : u?.fullName}
                                </div>
                                <div style={{ display: 'flex', alignItems: "flex-end", flexDirection: 'column' }}>
                                  {u?.id !== user?.id ? (
                                    <div style={{}}>
                                      <ActiveInactiveLable
                                        chatDetails={{ isOnline: u.isOnline }}
                                      />
                                      {
                                        u.isAdmin ?
                                          <span style={{ fontWeight: "500", color: "grey", marginRight: 10, }}>{isGerm ? DE.admin : EN.admin}</span> : <></>
                                      }
                                    </div>
                                  ) : (
                                    u.isAdmin ?
                                      <span style={{ fontWeight: "500", color: "grey", marginRight: 10, }}>{isGerm ? DE.admin : EN.admin}</span> : <></>
                                  )}

                                  {!u?.isAdmin && !props?.chat?.defaultChatGroup &&
                                    admin?.id === user?.id &&
                                    u?.id !== user?.id &&
                                    !props.project.viewByOwner && (!props.fromActivity && !props.project.isDone) && (
                                      <div
                                        style={{ marginTop: 5 }}
                                        onClick={() => removeMember(u)}
                                      >
                                        <RemoveUserFromInfo
                                          chatDetails={{ isOnline: u.isOnline }}
                                        />
                                      </div>
                                    )}
                                </div>
                              </div>
                            </li>
                          ))}
                      </div>
                    )}
                    <li className={props.isInternal ? "li-internal" : "pointer"} onClick={exportChat} >
                      <span>
                        <img
                          width="18px"
                          src={
                            process.env.PUBLIC_URL + "/assets/img/Export.svg"
                          }
                        />
                      </span>{" "}
                      {isGerm ? DE.export_chat_group : EN.export_chat_group}
                    </li>
                    {!props.chat?.defaultChatGroup && (!props.chat?.isLeave && !props.projectDetails?.isDone) && !props.viewByOwner && !isMember && (!props.fromActivity && !props.project.isDone) && (!props.fromActivity && !props.project.isDone) && (
                      <li className={props.isInternal ? "li-internal" : "pointer"} onClick={toggleBlockUser}>
                        <span>
                          <img
                            width="20px"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/img/Block-one-on-one chat.svg"
                            }
                          />
                        </span>
                        {isGerm ? DE.leave_chat_group : EN.leave_chat_group}
                      </li>
                    )}
                  </ul>
                </div>
                {exportChats && (
                  <div style={{ height: 1 }}>
                    <ExportChat
                      chat={props.chat}
                      hide={() => setExportChats(false)}
                      chatMessages={chatMessages}
                    />
                  </div>
                )}
              </div>
              <div
                onClick={() => {
                  if (!userN?.emailVerified) {
                    $crud.confirm({
                      title: "",
                      textContent: isGerm ? DE.text_to_verifiy : EN.text_to_verifiy,
                      options: {
                        ok: isGerm ? DE.ok : EN.ok,
                        cancel: "",
                      }
                    });
                  }
                }}
                style={{ display: "flex", paddingLeft: 10, paddingRight: 10 }} className="project-checkbox">
                <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
                  <span className="text" style={{ fontSize: '14px', color: !userN?.emailVerified ? "#dfdfdf" : "#000" }}>
                    {isGerm ? DE.email_on_grp : EN.email_on_grp}
                  </span>
                  <a style={{ marginLeft: 5 }} href="#"
                    onClick={(e) => {
                      $crud.confirm({
                        title: "",
                        textContent: isGerm ? DE.email_groups : EN.email_groups,
                        options: {
                          ok: isGerm ? DE.ok : EN.ok,
                          cancel: "",
                        },
                      });
                    }}
                  >
                    <img
                     style={{marginBottom:5}}
                      width={16}
                      src={
                        !userN.emailVerified ? process.env.PUBLIC_URL + "/assets/img/info-icon-disabled.svg" : process.env.PUBLIC_URL + "/assets/img/info-icon.svg"
                      }
                    />
                  </a>
                </div>
                <Form>
                  <Form.Check
                    checked={isEmailOn}
                    onChange={() => { _onPreEmailNotification() }}
                    type="switch"
                    id={`custom-switchx-setting-user-group-chat-${props.chat.id}`}
                    style={{}}
                    disabled={!userN?.emailNotification || !props.emailNotification || !userN?.emailVerified}
                  />
                </Form>
              </div>
            </CustomScrollbars>
          </div>
        </div >
      )
      }
      {
        (addUserAction.status && !addChatUserAction.status) ? (
          <div
            className="w-300 p-0 m-0"
            style={{
              height: "93vh",
              borderColor: props.project.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color,
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column"
            }}
          >
            <AddProjectUser
              project_id={props.project.id}
              handleEditClose={() => {
                setAddUserAction({ status: false, id: null });
              }}
              addAsMember={true}
              setAllUsers={setAllUsers}
              allUsers={allUsers}
              setAddChatUserAction={setAddChatUserAction}
              setAddUserAction={setAddUserAction}
              isInternal={props.isInternal}
              cID={props.cID}
              groupId={props.chat.id}
              Mems={props.Mems}
            />
          </div>
        ) : <></>
      }
      {
        addChatUserAction.status ?
          <div
            className="w-300 p-0 m-0"
            style={{
              height: "93vh",
              borderColor: props.project.isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color,
              backgroundColor: "white"
            }}
          >
            <AddProjectChatUser
              addMember={() => {
                setAddUserAction({ status: true, id: props.project.id, isInternal: props.project.isInternal, cID: props.project.cID });
                setAddChatUserAction({ status: false, id: null });
              }}
              users={props.users ? props.users : []}
              project_id={addChatUserAction.id}
              handleEditClose={() => {
                setAddChatUserAction({ status: false, id: null });
                // getMembers();
              }}
              setAllUsers={setAllUsers}
              allUsers={allUsers}
              isInternal={addChatUserAction.isInternal}
              cID={addChatUserAction.cID}
            />
          </div>
          :
          <></>
      }
    </React.Fragment >
  );
}

const mapStateToProps = (state) => {
  const { openChats, groups } = state;
  return {
    groups: groups,
    openChats: openChats,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateGroups: (groups) => dispatch({ type: GROUPS, groups: groups }),
    updateOpenChatsList: (chats) =>
      dispatch({ type: OPEN_CHATS, openChats: chats }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserInfo));